import { ItemOverheadApi } from "api"
import Axios from "axios"
import {
  ActionButton,
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  ExportButton,
  Input,
  InputQtyDecimal,
  InputSearch,
  Pagination,
  Select,
  SelectSearch,
  Switch,
  Td,
  TdFixed,
  Th,
  UpdateButton,
} from "components"
import FileSaver from "file-saver"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { ButtonGroup, Modal } from "react-bootstrap"
import {
  IoAddOutline,
  IoPencilOutline,
  IoTrashBinOutline,
} from "react-icons/io5"
import { TableNumber } from "utilities"
import * as Yup from "yup"

const RegistrasiOverhead = ({ setNavbarTitle }) => {
  // Title
  const title = "Item Overhead"
  const { REACT_APP_EXPORT_FILE_BASE_URL } = process.env

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true)

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false)
  // const [searchKey, setSearchKey] = useState("");
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [searchTime, setSearchTime] = useState(null)

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false) // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false) // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false) // MODAL HAPUS STATE

  // STATE DATA OVERHEAD
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [dataLength, setDataLength] = useState(10)
  const [totalData, setTotalData] = useState("")
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([])
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([])

  // STATE MENAMPUNG GENERATE KODE ITEM
  const [kodeItem, setKodeItem] = useState([])
  // STATE MENAMPUNG DATA BUASO SELECT
  const [buasoData, setBuasoData] = useState([])
  // STATE MENAMPUNG DATA SATUAN SELECT SEARCH
  // STATE MENAMPUNG DATA SATUAN SELECT SEARCH
  const [satuanData, setSatuanData] = useState([])
  // STATE MENAMPUNG DATA KELOMPOK SELECT SEARCH
  const [kelompokData, setKelompokData] = useState([])

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false)
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    setShowAlert(false)

    Axios.all([
      ItemOverheadApi.getPage(page, dataLength, searchConfig.key),
      ItemOverheadApi.getBuaso(),
      ItemOverheadApi.getSatuan(),
      ItemOverheadApi.getKelompok(),
      ItemOverheadApi.generateKodeItem(),
    ])
      .then(
        Axios.spread((res, buaso, satuan, kelompok, kodeItem) => {
          setData(res.data.data)
          setBuasoData(buaso.data.data)
          setSatuanData(satuan.data.data)
          setKelompokData(kelompok.data.data)
          setKodeItem(kodeItem.data.data)
          setTotalPage(res.data.total_page)
          setTotalData(res.data.data_count)
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        // if (searchKey != "") {
        //   setAlertConfig({
        //     variant: "primary",
        //     text: `Hasil Pencarian : ${searchKey}`,
        //   });
        //   setShowAlert(true);
        // }
        setIsLoading(false)
      })
  }

  // FUNCTION CARI DATA DARI SERVER
  // const searchData = (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   const key = searchConfig.key; // SearchKey Value as key

  //   ItemOverheadApi.search(key)
  //     .then((res) => {
  //       setData(res.data.data);
  //       setTotalPage(res.data.total_page);
  //     })
  //     .catch((err) => alert(err))
  //     .finally(() => {
  //       setIsSearching(true);
  //       setIsLoading(false);
  //       setAlertConfig({
  //         variant: "primary",
  //         text: `Hasil Pencarian : ${key}`,
  //       });
  //       setShowAlert(true);
  //     });
  // };

  const onInputSearchChange = (e) => {
    const key = e.target.value
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        })
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        })
      }, 750)
    )
  }

  // FUNCTION UBAH SWITCH SHOW/HIDE STATUS
  const changeDataStatus = (status, id) => {
    // setIsLoading(true);
    setShowAlert(false)

    const value = {
      id_item_buaso: id,
    }

    const onLoadedSuccess = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      })
      setShowAlert(true)
    }

    const onLoadedFailed = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      })
      setShowAlert(true)
    }

    status === true
      ? ItemOverheadApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
      : ItemOverheadApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title) // SET JUDUL NAVBAR
    getData() // GET DATA DARI SERVER

    return () => {
      setIsLoading(false)
      setIsSearching(false)
    }
  }, [setNavbarTitle, page, dataLength, searchConfig.key])

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    nama_item: Yup.string()
      // .test("checkNama", "Nama tidak dapat digunakan", (value) =>
      //   RegItemOverheadApi.single("nama_item", value)
      //     .then(() => false)
      //     .catch(() => true)
      // )
      .required("Masukkan Nama Item Overhead"),
    // keterangan: Yup.string().required("Masukkan Keterangan"),
    id_satuan: Yup.string().required("Pilih Satuan"),
    id_kelompok: Yup.string().required("Pilih Kelompok"),
    persentase_pembebanan: Yup.number()
      .max(100, "Maksimal 100")
      .required("Masukan Persentase Pembebanan"),
  })

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    // FORMIK INITIAL VALUES
    const formInitialValues = {
      id_buaso: "5", // SET ID BUASO BAHAN
      kode_item: kodeItem,
      nama_item: "",
      keterangan: "",
      id_satuan: "",
      id_kelompok: "",
      persentase_pembebanan: 0,
    }

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      setIsCreateForm(false)

      ItemOverheadApi.create(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          })
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          })
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false)
          // TAMPILKAN ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }

    return (
      <Modal
        show={isCreateForm}
        onHide={() => setIsCreateForm(false)}
        title={title}>
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            <h6 className="mb-0">
              <IoAddOutline className="mb-1 mr-2" size={20} />
              Tambah Data {title}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}>
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Select
                  label="BUASO"
                  name="id_buaso"
                  onChange={handleChange}
                  errorText={errors.id_buaso}
                  defaultValue={values.id_buaso} // ISI DEFAULT VALUE DENGAN id_buaso yang memiliki value bahan
                  disabled={true}>
                  {/* OPTIONS */}
                  {buasoData.map((e) => (
                    <option key={e.id_buaso} value={e.id_buaso}>
                      {e.kode_buaso} - {e.nama_buaso}
                    </option>
                  ))}
                </Select>

                <Input
                  label="Kode Overhead"
                  type="text"
                  name="kode_item"
                  placeholder="Kode Overhead"
                  value={values.kode_item}
                  // readOnly={true}
                  onChange={handleChange}
                />

                <Input
                  label="Nama Item Overhead"
                  type="text"
                  name="nama_item"
                  placeholder="Item Overhead"
                  value={values.nama_item}
                  onChange={handleChange}
                  error={errors.nama_item && touched.nama_item && true}
                  errorText={errors.nama_item}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                />

                <SelectSearch
                  label="Satuan Pakai"
                  name="id_satuan"
                  placeholder="Pilih Satuan Pakai"
                  onChange={(val) => {
                    setFieldValue("id_satuan", val.value)
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={satuanData.map((val) => {
                    return {
                      value: val.id_satuan,
                      label: val.nama_satuan,
                    }
                  })}
                  defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                  error={errors.id_satuan && touched.id_satuan && true}
                  errorText={
                    errors.id_satuan && touched.id_satuan && errors.id_satuan
                  }
                />

                <SelectSearch
                  label="Kelompok"
                  name="id_kelompok"
                  placeholder="Pilih Kelompok"
                  onChange={(val) => {
                    setFieldValue("id_kelompok", val.value)
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={kelompokData.map((val) => {
                    return {
                      value: val.id_kelompok,
                      label: val.nama_kelompok,
                    }
                  })}
                  defaultValue=""
                  error={errors.id_kelompok && touched.id_kelompok && true}
                  errorText={
                    errors.id_kelompok &&
                    touched.id_kelompok &&
                    errors.id_kelompok
                  }
                />
                <InputQtyDecimal
                  label="Persentase Pembebanan (%)"
                  placeholder="Masukan Persentase Pembebanan"
                  value={values.persentase_pembebanan}
                  onChange={(e) => setFieldValue("persentase_pembebanan", e)}
                  error={
                    errors.persentase_pembebanan &&
                    touched.persentase_pembebanan &&
                    true
                  }
                  errorText={
                    errors.persentase_pembebanan &&
                    touched.persentase_pembebanan &&
                    errors.persentase_pembebanan
                  }
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    )
  }

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_buaso: updateData.id_buaso, // SET ID BUASO OVERHEAD
      kode_item: updateData.kode_item,
      nama_item: updateData.nama_item,
      keterangan: updateData.keterangan,
      id_satuan: updateData.id_satuan,
      id_kelompok: updateData.id_kelompok,
      persentase_pembebanan: updateData.persentase_pembebanan ?? 0,
    }

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_item_buaso: updateData.id_item_buaso,
        ...values,
      }
      ItemOverheadApi.update(finalValues)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          })
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Ubah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          })
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false)
          // SHOW ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }

    return (
      <Modal
        show={isUpdateForm}
        onHide={() => setIsUpdateForm(false)}
        title={title}>
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <h6 className="mb-0">
              <IoPencilOutline className="mb-1 mr-2" size={20} />
              Ubah Data {title}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}>
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Select
                  label="BUASO"
                  name="id_buaso"
                  onChange={handleChange}
                  errorText={errors.id_buaso}
                  defaultValue={values.id_buaso} // ISI DEFAULT VALUE DENGAN id_buaso yang memiliki value bahan
                  disabled={true}>
                  {/* OPTIONS */}
                  {buasoData.map((e) => (
                    <option key={e.id_buaso} value={e.id_buaso}>
                      {e.kode_buaso} - {e.nama_buaso}
                    </option>
                  ))}
                </Select>

                <Input
                  label="Kode Overhead"
                  type="text"
                  name="kode_item"
                  placeholder="Kode Overhead"
                  value={values.kode_item}
                  // readOnly={true}
                  onChange={handleChange}
                />

                <Input
                  label="Nama Item Overhead"
                  type="text"
                  name="nama_item"
                  placeholder="Item Overhead"
                  value={values.nama_item}
                  onChange={handleChange}
                  error={errors.nama_item && touched.nama_item && true}
                  errorText={errors.nama_item}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                />

                <SelectSearch
                  label="Satuan Pakai"
                  name="id_satuan"
                  placeholder="Pilih Satuan Pakai"
                  onChange={(val) => {
                    setFieldValue("id_satuan", val.value)
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={satuanData.map((val) => {
                    return {
                      value: val.id_satuan,
                      label: val.nama_satuan,
                    }
                  })}
                  defaultValue={{
                    value: updateData.id_satuan ?? "",
                    label:
                      updateData.id_satuan === ""
                        ? "Pilih Satuan"
                        : satuanData.find(
                          (val) => val.id_satuan === updateData.id_satuan
                        )?.nama_satuan ?? "Pilih Satuan",
                  }}
                  error={errors.id_satuan && touched.id_satuan && true}
                  errorText={
                    errors.id_satuan && touched.id_satuan && errors.id_satuan
                  }
                />

                <SelectSearch
                  label="Kelompok"
                  name="id_kelompok"
                  placeholder="Pilih Kelompok"
                  onChange={(val) => {
                    setFieldValue("id_kelompok", val.value)
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={kelompokData.map((val) => {
                    return {
                      value: val.id_kelompok,
                      label: val.nama_kelompok,
                    }
                  })}
                  defaultValue={{
                    value: updateData.id_kelompok ?? "",
                    label:
                      updateData.id_kelompok === ""
                        ? "Pilih Kelompok"
                        : kelompokData.find(
                          (val) => val.id_kelompok === updateData.id_kelompok
                        )?.nama_kelompok ?? "Pilih Kelompok",
                  }}
                  error={errors.id_kelompok && touched.id_kelompok && true}
                  errorText={
                    errors.id_kelompok &&
                    touched.id_kelompok &&
                    errors.id_kelompok
                  }
                />
                <InputQtyDecimal
                  label="Persentase Pembebanan (%)"
                  placeholder="Masukan Persentase Pembebanan"
                  value={parseFloat(values.persentase_pembebanan)}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      persentase_pembebanan: e,
                    })
                  }
                  error={
                    errors.persentase_pembebanan &&
                    touched.persentase_pembebanan &&
                    true
                  }
                  errorText={
                    errors.persentase_pembebanan &&
                    touched.persentase_pembebanan &&
                    errors.persentase_pembebanan
                  }
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    )
  }

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_item_buaso: deleteData.id_item_buaso }

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false)

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true)

      ItemOverheadApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal!(${err.response.data.message})`,
          })
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false)
          // TAMPIL ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }

    return (
      <Modal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            <h6 className="mb-0">
              <IoTrashBinOutline className="mb-1 mr-2" size={20} />
              Hapus Data {title}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 className="mb-1">Apakah anda yakin menghapus data?</h5>
          <div>
            <div>Kode Overhead : {deleteData.kode_item}</div>
            <div>Nama Item Overhead : {deleteData.nama_item}</div>
          </div>
          <small className="text-danger">
            Data yang telah dihapus tidak dapat dikembalikan
          </small>
          <div className="mt-3">
            <ActionButton
              variant="outline-secondary"
              text="Batal"
              onClick={() => setIsDeleteData(false)}
            />
            <ActionButton
              variant="danger"
              text="Hapus data"
              onClick={deleteDataHandler}
              loading={btnLoading}
              className="ml-1"
            />
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <thead>
            <tr className="text-center">
              <Th>No.</Th>
              <Th className="text-center">Aksi</Th>
              <Th>Kode</Th>
              <Th>Nama Item Overhead</Th>
              <Th>Satuan Pakai</Th>
              <Th>Kelompok</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => {
              return (
                <tr key={val.id_item_buaso}>
                  <Td className="text-center" width="20px">
                    {TableNumber(page, dataLength, index)}
                  </Td>
                  <TdFixed>
                    <div className="d-flex justify-content-start align-items-center">
                      <ButtonGroup size="sm">
                        <UpdateButton
                          size="sm"
                          onClick={() => {
                            setUpdateData(val)
                            setIsUpdateForm(true)
                          }}
                        />
                        <DeleteButton
                          size="sm"
                          onClick={() => {
                            setDeleteData(val)
                            setIsDeleteData(true)
                          }}
                        />
                      </ButtonGroup>

                      <Switch
                        id={val.id_item_buaso}
                        checked={val.is_hidden ? false : true}
                        wrapperClassName="m-1"
                        onChange={() =>
                          changeDataStatus(val.is_hidden, val.id_item_buaso)
                        }
                      />
                    </div>
                  </TdFixed>
                  <Td width="20px">{val.kode_item}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_satuan ?? "Pilih Satuan"}</Td>
                  <Td>{val.nama_kelompok ?? "Pilih Kelompok"}</Td>
                </tr>
              )
            })}
          </tbody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                  if (index == data.length - 1) {
                    return TableNumber(page, dataLength, index)
                  }
                })
            }
            dataNumber={data.map((res, index) => {
              if (index == 0) {
                return TableNumber(page, dataLength, index)
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value)
              setPage(1)
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={onInputSearchChange}
            // onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          <ExportButton
            onClick={() => {
              setIsLoading(true)
              ItemOverheadApi.export()
                .then((val) => {
                  const urlFile =
                    REACT_APP_EXPORT_FILE_BASE_URL + val.data.file
                  FileSaver.saveAs(urlFile, val.data.file)
                })
                .catch((res) => alert(res))
                .finally(() => {
                  setIsLoading(false)
                })
            }}
            className="mr-2"
          />
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  )
}

export default RegistrasiOverhead
