// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Components
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DataStatus,
  Alert,
  Pagination,
  FilterButton,
} from "components";
import { TableNumber as PageNumber } from "utilities";

// API
import { TransferOverheadJobMixApi } from "api";

// View Components
import { ExportButton, ModalFilter, TableTransfer } from "./components";
import FileSaver from "file-saver";
import { Dropdown } from "react-bootstrap";

const TransferOverheadJobMix = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  const location = useLocation();

  // Variables
  const title = "Transfer Overhead Produksi";
  const filter = location.state?.filter ?? {};

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // data jenis
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: filter.pre?.active,
    page: filter.pre?.page ?? 1,
    per_page: filter.pre?.per_page ?? 10,
    q: filter.pre?.q ?? "",
    tgl_transfer_produksi_overhead_awal:
      filter.pre?.tgl_transfer_produksi_overhead_awal,
    tgl_transfer_produksi_overhead_akhir:
      filter.pre?.tgl_transfer_produksi_overhead_akhir,
    tgl_permintaan_produksi_overhead_awal:
      filter.pre?.tgl_permintaan_produksi_overhead_awal,
    tgl_permintaan_produksi_overhead_akhir:
      filter.pre?.tgl_permintaan_produksi_overhead_akhir,
    id_item_buaso: filter.pre?.id_item_buaso,
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  const [loadingExport, setLoadingExport] = useState(false);

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    const filterData = { ...dataFilter };
    delete filterData.active;

    TransferOverheadJobMixApi.page(filterData)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: text.target.value,
        });
        setDataFilter({
          ...dataFilter,
          page: 1,
          q: key,
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    TransferOverheadJobMixApi.export({
      tgl_transfer_produksi_overhead_awal:
        dataFilter?.tgl_transfer_produksi_overhead_awal,
      tgl_transfer_produksi_overhead_akhir:
        dataFilter?.tgl_transfer_produksi_overhead_akhir,
      tgl_permintaan_produksi_overhead_awal:
        dataFilter?.tgl_permintaan_produksi_overhead_awal,
      tgl_permintaan_produksi_overhead_akhir:
        dataFilter?.tgl_permintaan_produksi_overhead_akhir,
      id_item_buaso: dataFilter?.id_item_buaso,
      tipe,
    })
      .then((res) => {
        const data = res.data.data;
        // const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data;
        FileSaver.saveAs(data, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    checkAlert();

    // eslint-disable-next-line
  }, [setNavbarTitle]);

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [dataFilter]);

  const toggleModal = () => setModalFilter(!modalFilter);

  // Tabel
  const Table = () => {
    return (
      <>
        <b>List Data {title}</b>
        <TableTransfer
          data={data}
          isPre
          dataFilter={dataFilter}
          title={title}
        />
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((_res, index) => {
                  if (index === data.length - 1) {
                    return PageNumber(
                      dataFilter.page,
                      dataFilter.per_page,
                      index
                    );
                  } else {
                    return null;
                  }
                })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return PageNumber(dataFilter.page, dataFilter.per_page, index);
            } else {
              return null;
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1,
            });
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearchChange} />
            <FilterButton active={dataFilter?.active} onClick={toggleModal} />
            <ExportButton loading={loadingExport} >
              <Dropdown.Item onClick={() => exportData("excel")}>
                Excel (.xlsx)
              </Dropdown.Item>
            </ExportButton>
          </div>
        </CRUDLayout.HeadSearchSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
          // CLEAR HISTORY LOCATION
          history.replace("/transaksi/transfer-overhead-job-mix", {
            registerAlertConfig: undefined,
          });
        }}
      />

      {/* Table Section */}
      {isLoading ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
      {modalFilter && (
        <ModalFilter
          toggle={toggleModal}
          data={dataFilter}
          setData={setDataFilter}
          buaso="overhead"
        />
      )}
    </CRUDLayout>
  );
};

export default TransferOverheadJobMix;
