// React
import { useEffect, useState } from "react"

// API
import { HargaPerkiraanSendiriApi } from "api"

// Components
import {
  ActionButton,
  Alert,
  CRUDLayout,
  DataStatus,
  NumberFormat,
  Pagination,
  Td,
  TdFixed,
  Th,
  UpdateButton
} from "components"
import { Modal } from "react-bootstrap"
import { DateConvert, RupiahConvert, TableNumber } from "utilities"

// Form
import { Formik } from "formik"
import * as Yup from "yup"

// View Functions
import { Timestamp } from "../functions"

const TabItem = ({ q, filter, value }) => {
  // States
  const [dataFilter, setDataFilter] = useState({
    page: 1,
    per_page: 10,
  });
  const [page, setPage] = useState({
    loading: false,
    status: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: `Hasil Pencarian : ${q}`,
  });
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [modal, setModal] = useState({
    show: false,
    data: {},
  });

  const toggleModal = () => setModal({ ...modal, show: !modal.show });

  const mappingData = (value) => {
    const buaso = (value) => {
      switch (value) {
        case "1":
          return "B";
        case "2":
          return "U";
        case "3":
          return "A";
        case "4":
          return "S";
        case "5":
          return "O";
        default:
          return null;
      }
    };

    return value.map((val) => {
      return {
        ...val,
        buaso: buaso(val.id_buaso),
      };
    });
  };

  const getData = () => {
    setPage({
      loading: true,
      status: false,
    });

    HargaPerkiraanSendiriApi.page(value, {
      ...dataFilter,
      q,
      id_buaso: Boolean(filter && filter !== "") ? filter : undefined,
    })
      .then((res) => {
        setData(mappingData(res.data.data));
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);

        setPage({
          loading: false,
          status: true,
        });
      })
      .catch(() => {
        setPage({
          loading: false,
          status: false,
        });
      });
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    getData(); // GET DATA DARI SERVER

    // eslint-disable-next-line
  }, [q, filter, dataFilter]);

  const DataModal = () => {
    const List = ({ label, children }) => (
      <tr>
        <Td className="text-nowrap text-capitalize">{label}</Td>
        <Td className="mx-50">:</Td>
        <Td>{children}</Td>
      </tr>
    );
    return (
      <Modal show={modal.show} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <h6 className="mb-0">Ubah Data Harga Perkiraan Sendiri</h6>
          </Modal.Title>
        </Modal.Header>

        <Formik
          enableReinitialize
          initialValues={{
            id_item_hps: modal.data.id_item,
            hps_beli: parseFloat(modal.data.hps_beli),
            hps_pakai: parseFloat(modal.data.hps_pakai),
            buaso: modal.data.buaso,
            tgl_harga_perkiraan_sendiri: `${DateConvert(new Date()).default} ${Timestamp(
              new Date()
            )}`,
            hps: modal.data[`hps_${value}`] ? parseFloat(modal.data[`hps_${value}`]) : "",
          }}
          validationSchema={Yup.object().shape({
            hps: Yup.string()
              .required(`Harga Perkiraan Sendiri Per Satuan ${value} wajib diisi`)
              .nullable(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            HargaPerkiraanSendiriApi.update({
              ...values,
              [`hps_${value}`]: values.hps,
            })
              .then(() => {
                setAlertConfig({
                  show: true,
                  variant: "primary",
                  text: "Ubah data berhasil!",
                });
              })
              .catch(() => {
                setAlertConfig({
                  show: true,
                  variant: "danger",
                  text: "Ubah data gagal!",
                });
              })
              .finally(() => {
                toggleModal();
                setSubmitting(false);
                getData();
              });
          }}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <table>
                  <tbody>
                    <List label="Kode Item">{modal.data.kode_item ?? "-"}</List>
                    <List label="Nama Item">{modal.data.nama_item ?? "-"}</List>
                    <List label="Grup BUASO">{modal.data.grup_buaso ?? "-"}</List>
                    <List label={`Satuan ${value}`}>{modal.data[`satuan_${value}`] ?? "-"}</List>
                    <List label="Tgl. Update Terakhir">
                      {modal.data.tgl_harga_perkiraan_sendiri
                        ? `${
                            DateConvert(new Date(modal.data.tgl_harga_perkiraan_sendiri)).defaultDMY
                          } ${Timestamp(modal.data.tgl_harga_perkiraan_sendiri)}`
                        : "-"}
                    </List>
                  </tbody>
                </table>
                <hr />

                <NumberFormat
                  label={`Harga Perkiraan Sendiri Per Satuan ${value}`}
                  value={values.hps}
                  defaultValue={values.hps}
                  onChange={(value) => setFieldValue("hps", value ?? "")}
                  decimalScale={2}
                  prefix="Rp"
                  className="text-right"
                  error={Boolean(errors.hps && touched.hps) && true}
                  errorText={errors.hps}
                />

                {/* <InputCurrency
                  label="Harga Kesepakatan"
                  placeholder="Masukan harga kesepakatan"
                  value={parseFloat(values.hps)}
                  onChange={(e) => {
                    setFieldValue("hps", e);
                  }}
                  error={Boolean(errors.hps && touched.hps) && true}
                  errorText={errors.hps}
                /> */}
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <thead>
            <tr className="text-center">
              <Th>No.</Th>
              <Th>Kode Item</Th>
              <Th>Nama Item</Th>
              <Th>Grup BUASO</Th>
              <Th className="text-capitalize" style={{ width: "100px" }}>
                Satuan {value}
              </Th>
              <Th className="text-capitalize" style={{ width: "150px" }}>
                HPS {value}
              </Th>
              <Th>Tgl. Update Terakhir</Th>
              <Th>Aksi</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => {
              return (
                <tr key={index}>
                  <Td className="text-nowrap">
                    {TableNumber(dataFilter.page, dataFilter.per_page, index)}
                  </Td>
                  <Td className="text-nowrap">{val.kode_item ? val.kode_item : "-"}</Td>
                  <Td>{val.nama_item ? val.nama_item : "-"}</Td>
                  <Td>{val.grup_buaso ? val.grup_buaso : "-"}</Td>
                  <Td>{val[`satuan_${value}`] ? val[`satuan_${value}`] : "-"}</Td>
                  <Td className="text-right text-nowrap">
                    {val[`hps_${value}`]
                      ? RupiahConvert(parseFloat(val[`hps_${value}`]).toString()).getWithComa
                      : "Rp 0"}
                  </Td>
                  <Td className="text-nowrap">
                    {val.tgl_harga_perkiraan_sendiri
                      ? `${
                          DateConvert(new Date(val.tgl_harga_perkiraan_sendiri)).defaultDMY
                        } ${Timestamp(val.tgl_harga_perkiraan_sendiri)}`
                      : "-"}
                  </Td>
                  <TdFixed> 
                    <UpdateButton
                      size="sm"
                      onClick={() => {
                        setModal({
                          show: true,
                          data: val,
                        });
                      }}
                    />
                  </TdFixed>
                </tr>
              );
            })}
          </tbody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((_res, index) => {
                  if (index === data.length - 1) {
                    return TableNumber(dataFilter.page, dataFilter.per_page, index);
                  } else {
                    return null;
                  }
                })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return TableNumber(dataFilter.page, dataFilter.per_page, index);
            } else {
              return null;
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1,
            });
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  return (
    <>
      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {page.loading || !page.status || data.length === 0 ? (
        <DataStatus
          loading={page.loading}
          text={
            page.loading
              ? "Memuat data..."
              : !page.status
              ? "Data gagal dimuat, tidak dapat menampilkan data"
              : "Tidak ada data"
          }
        />
      ) : (
        <Table />
      )}

      {modal && <DataModal />}
    </>
  );
};

export default TabItem;
