import { useState } from "react"
import { IoCloseOutline } from "react-icons/io5"
import {
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ReadButton,
  DataStatus,
  Pagination,
  ActionButton,
  ApprovalStatusButton,
  InputSearch,
  FilterButton,
  Alert,
} from "components"
import {
  DateConvert,
  DecimalConvert,
  RupiahConvert,
  TableNumber,
} from "utilities"
import {
  ExportButton,
  ModalBDPProduksi,
  ModalFilterBDPProduksiList,
} from "../Comps"
import { generateNameHandler, getTotal } from "../Utils"
import { Dropdown } from "react-bootstrap"
import { BDPProduksiApi } from "api"
import FileSaver from "file-saver"

export const TableBDPProduksiList = ({
  data = [],
  pagination,
  setPagination,
  type,
  fetchingStatus,
  searchConfig,
  setSearchConfig,
  searchTime,
  setSearchTime,
  setDataFilter,
  dataFilter,
}) => {
  const generateName = generateNameHandler(type)
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {},
  })
  const [modalFilter, setModalFilter] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [alertPerTab, setAlertPerTab] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  if (fetchingStatus?.loading || fetchingStatus?.error) {
    return (
      <div className="p-3 bg-white border border-top-0">
        <DataStatus
          loading={fetchingStatus.loading}
          text={
            fetchingStatus.loading ? "Memuat data . . ." : "Data gagal dimuat"
          }
        />
      </div>
    )
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value
    setSearchValue(key)
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          [type]: e.target.value,
        })
        setAlertPerTab({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian:" + key,
        })
      }, 750)
    )
  }
  const exportData = (tipe, item) => {
    setLoadingExport(true)

    BDPProduksiApi.export(
      {
        tipe,
        ...dataFilter?.[type],
        q: searchConfig?.[type],
      },
      item
    )
      .then((res) => {
        const data = res.data.data

        FileSaver.saveAs(data, data)
      })
      .catch(() => {
        setAlertPerTab({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        })
      })
      .finally(() => setLoadingExport(false))
  }

  const toggleModalFilter = () => setModalFilter(!modalFilter)

  return (
    <div className="p-3 bg-white border border-top-0">
      <div className="d-flex">
        <InputSearch onChange={onInputSearchChange} value={searchValue} />
        <FilterButton
          active={dataFilter?.[type]?.active}
          onClick={toggleModalFilter}
        />
        <ExportButton loading={loadingExport}>
          <Dropdown.Item onClick={() => exportData("excel", type)}>
            Excel (.xlsx)
          </Dropdown.Item>
        </ExportButton>
      </div>

      {/* Alert */}
      <Alert
        show={alertPerTab.show}
        variant={alertPerTab.variant}
        text={alertPerTab.text}
      />

      <b className="text-capitalize">List Data BDP {generateName}</b>
      <Table responsive>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th style={{ width: 200 }} className="p-1">
              Informasi BDP {generateName}
            </Th>
            <Th style={{ width: 200 }} className="p-1">
              Informasi Realisasi Produksi
            </Th>
            <Th style={{ minWidth: 200 }} className="p-1">
              Item {generateName}
            </Th>
            {type === "upah" || type === "subkon" ? (
              <Th style={{ minWidth: 200 }}>Vendor</Th>
            ) : (
              <></>
            )}
            <Th style={{ minWidth: 80 }} className="p-1">
              Qty.
            </Th>
            <Th style={{ minWidth: 150 }} className="p-1">
              Harga Satuan
            </Th>
            <Th style={{ minWidth: 150 }} className="p-1">
              Jumlah
            </Th>
            <Th style={{ minWidth: 300 }} className="p-1">
              Akun COA
            </Th>
            <Th style={{ minWidth: 150 }} className="p-1">
              Keterangan Jurnal
            </Th>
          </Tr>
        </THead>
        <TBody>
          {data && data?.length > 0 ? (
            data.map((e, i) => (
              <Tr key={i}>
                <TdFixed textCenter>
                  {TableNumber(pagination?.page, pagination?.dataLength, i)}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex">
                    <ReadButton
                      size="sm"
                      className="mr-1"
                      onClick={() =>
                        setModal({ show: true, action: "detail", data: e })
                      }
                    />
                  </div>
                </TdFixed>
                <Td>
                  <span className="d-block">
                    {e?.tgl_bdp
                      ? DateConvert(new Date(e.tgl_bdp)).defaultDMY
                      : "-"}
                  </span>
                  <span className="d-block">{e?.no_bdp ?? "-"}</span>
                </Td>
                <Td>
                  <span className="d-block">
                    {e?.tgl_realisasi_produksi
                      ? DateConvert(new Date(e.tgl_realisasi_produksi))
                        .defaultDMY
                      : "-"}
                  </span>
                  <span className="d-block">
                    {e?.no_realisasi_produksi ?? "-"}
                  </span>
                </Td>
                <Td>{e?.nama_item ?? "-"}</Td>
                {type === "upah" || type === "subkon" ? (
                  <Td>{e?.nama_vendor ?? "-"}</Td>
                ) : (
                  <></>
                )}
                <Td>
                  {e?.qty_realisasi
                    ? DecimalConvert(e.qty_realisasi).getWithComa
                    : "-"}{" "}
                  {e?.satuan ?? ""}
                </Td>
                <Td textRight>
                  {e?.harga_satuan
                    ? RupiahConvert(parseFloat(e.harga_satuan).toString())
                      .getWithComa
                    : "-"}
                </Td>
                <Td textRight>
                  {RupiahConvert(getTotal(e).toString()).getWithComa}
                </Td>
                <Td>
                  <span className="d-block">
                    D: {e?.nomor_akun_debet ?? ""} - {e?.nama_akun_debet ?? ""}
                  </span>
                  <div className="mt-2" />
                  <span className="d-block">
                    K: {e?.nomor_akun_kredit ?? ""} -{" "}
                    {e?.nama_akun_kredit ?? ""}
                  </span>
                </Td>
                <Td>{e?.keterangan ?? "-"}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={11}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      {data?.length ? (
        <Pagination
          dataLength={pagination?.dataLength}
          dataNumber={
            pagination?.page * pagination?.dataLength - pagination?.dataLength + 1
          }
          dataPage={
            pagination?.dataCount < pagination?.dataLength
              ? pagination?.dataCount
              : pagination?.page * pagination?.dataLength
          }
          dataCount={pagination?.dataCount}
          currentPage={pagination?.page}
          totalPage={pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setPagination({
              ...pagination,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPagination({
              ...pagination,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      ) : null}

      <ModalBDPProduksi
        show={modal.show}
        action={modal.action}
        type={type}
        data={modal.data}
        onHide={() =>
          setModal({
            show: false,
            action: "",
            data: {},
          })
        }
      />

      {modalFilter && (
        <ModalFilterBDPProduksiList
          toggle={toggleModalFilter}
          data={dataFilter}
          setData={setDataFilter}
          buaso={type}
          checkTitle={generateName}
        />
      )}
    </div>
  )
}
