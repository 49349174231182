import { ItemOverheadApi } from "api"
import Axios from "axios"
import {
  ActionButton,
  Alert,
  CRUDLayout,
  DataStatus,
  InfoItemHorizontal,
  InputSearch,
  Pagination,
  SelectSearch,
  Td,
  TdFixed,
  Th,
  UpdateButton
} from "components"
import FileSaver from "file-saver"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { ButtonGroup, Dropdown, Modal } from "react-bootstrap"
import {
  IoPencilOutline,
  IoTrashBinOutline
} from "react-icons/io5"
import { TableNumber } from "utilities"
import * as Yup from "yup"
import { ExportButton } from "./ExportButton"

const RegistrasiOverhead = ({ setNavbarTitle }) => {
  // Title
  const title = "MAPPING COA ITEM OVERHEAD"

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true)

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false)
  // const [searchKey, setSearchKey] = useState("");
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [searchTime, setSearchTime] = useState(null)

  // MODALS SHOW STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false) // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false) // MODAL HAPUS STATE

  // STATE DATA OVERHEAD
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [dataLength, setDataLength] = useState(10)
  const [totalData, setTotalData] = useState("")
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([])
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([])

  const [coaData, setCoaData] = useState([])

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false)
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })

  const [loadingExport, setLoadingExport] = useState(false)

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    setShowAlert(false)

    Axios.all([
      ItemOverheadApi.getPage(page, dataLength, searchConfig.key),
      ItemOverheadApi.getCOA(),
    ])
      .then(
        Axios.spread((res, coa) => {
          setData(res.data.data)
          setCoaData(coa.data.data)
          setTotalPage(res.data.total_page)
          setTotalData(res.data.data_count)
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        // if (searchKey != "") {
        //   setAlertConfig({
        //     variant: "primary",
        //     text: `Hasil Pencarian : ${searchKey}`,
        //   });
        //   setShowAlert(true);
        // }
        setIsLoading(false)
      })
  }

  // FUNCTION CARI DATA DARI SERVER
  // const searchData = (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   const key = searchConfig.key; // SearchKey Value as key

  //   ItemOverheadApi.search(key)
  //     .then((res) => {
  //       setData(res.data.data);
  //       setTotalPage(res.data.total_page);
  //     })
  //     .catch((err) => alert(err))
  //     .finally(() => {
  //       setIsSearching(true);
  //       setIsLoading(false);
  //       setAlertConfig({
  //         variant: "primary",
  //         text: `Hasil Pencarian : ${key}`,
  //       });
  //       setShowAlert(true);
  //     });
  // };

  const onInputSearchChange = (e) => {
    const key = e.target.value
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        })
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        })
      }, 750)
    )
  }

  const exportData = (tipe) => {
    setLoadingExport(true)

    ItemOverheadApi.export({
      tipe,
    })
      .then((res) => {
        const data = res.data.data
        // const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data;
        FileSaver.saveAs(data, data)
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        })
      })
      .finally(() => setLoadingExport(false))
  }

  // FUNCTION UBAH SWITCH SHOW/HIDE STATUS
  const changeDataStatus = (status, id) => {
    // setIsLoading(true);
    setShowAlert(false)

    const value = {
      id_item_buaso: id,
    }

    const onLoadedSuccess = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      })
      setShowAlert(true)
    }

    const onLoadedFailed = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      })
      setShowAlert(true)
    }

    status === true
      ? ItemOverheadApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
      : ItemOverheadApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title) // SET JUDUL NAVBAR
    getData() // GET DATA DARI SERVER

    return () => {
      setIsLoading(false)
      setIsSearching(false)
    }
  }, [setNavbarTitle, page, dataLength, searchConfig.key])

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    no_coa: Yup.string().required("Pilih COA").nullable(),
  })

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_buaso: updateData.id_buaso, // SET ID BUASO OVERHEAD
      id_item_buaso: updateData.id_item_buaso,
      no_coa: updateData.no_coa,
    }

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      ItemOverheadApi.updateCOA(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          })
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Ubah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          })
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false)
          // SHOW ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }

    return (
      <Modal
        show={isUpdateForm}
        onHide={() => setIsUpdateForm(false)}
        title={title}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <h6 className="mb-0">
              <IoPencilOutline className="mb-1 mr-2" size={20} />
              {title}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            errors,
            touched,
            isSubmitting,
            handleSubmit,
            setFieldValue,
            values,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <InfoItemHorizontal
                  label="Item Overhead"
                  text={updateData.nama_item}
                />
                <InfoItemHorizontal
                  label="Satuan Pakai"
                  text={updateData.nama_satuan}
                />
                <InfoItemHorizontal
                  label="Kelompok"
                  text={updateData.nama_kelompok}
                />
                <hr />
                <SelectSearch
                  label="COA"
                  name="no_coa"
                  placeholder="Pilih COA"
                  onChange={(val) => {
                    setFieldValue("no_coa", val.value)
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={coaData.map((val) => {
                    return {
                      value: val.nomor_akun,
                      label: val.nama_akun,
                    }
                  })}
                  defaultValue={{
                    value: updateData.no_coa ?? "",
                    label:
                      updateData.no_coa === ""
                        ? "Pilih COA"
                        : coaData.find(
                          (val) => val.nomor_akun === updateData.no_coa
                        )?.nama_akun ?? "Pilih COA",
                  }}
                  error={errors.no_coa && touched.no_coa && true}
                  errorText={errors.no_coa && touched.no_coa && errors.no_coa}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    )
  }

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_item_buaso: deleteData.id_item_buaso }

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false)

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true)

      ItemOverheadApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal!(${err.response.data.message})`,
          })
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false)
          // TAMPIL ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }

    return (
      <Modal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            <h6 className="mb-0">
              <IoTrashBinOutline className="mb-1 mr-2" size={20} />
              Hapus Data {title}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 className="mb-1">Apakah anda yakin menghapus data?</h5>
          <div>
            <div>Kode Overhead : {deleteData.kode_item}</div>
            <div>Nama Item Overhead : {deleteData.nama_item}</div>
          </div>
          <small className="text-danger">
            Data yang telah dihapus tidak dapat dikembalikan
          </small>
          <div className="mt-3">
            <ActionButton
              variant="outline-secondary"
              text="Batal"
              onClick={() => setIsDeleteData(false)}
            />
            <ActionButton
              variant="danger"
              text="Hapus data"
              onClick={deleteDataHandler}
              loading={btnLoading}
              className="ml-1"
            />
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <thead>
            <tr className="text-center">
              <Th>No.</Th>
              <Th>Kode</Th>
              <Th>Nama Item Overhead</Th>
              <Th>Satuan Pakai</Th>
              <Th>Kelompok</Th>
              <Th>COA</Th>
              <Th className="text-center">Aksi</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => {
              return (
                <tr key={val.id_item_buaso}>
                  <Td className="text-center" width="20px">
                    {TableNumber(page, dataLength, index)}
                  </Td>
                  <Td width="20px">{val.kode_item}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_satuan ?? "Pilih Satuan"}</Td>
                  <Td>{val.nama_kelompok ?? "Pilih Kelompok"}</Td>
                  <Td>
                    {Boolean(val.no_coa && val.nama_akun)
                      ? val.no_coa + " - " + val.nama_akun
                      : "-"}
                  </Td>
                  <TdFixed >
                    <ButtonGroup>
                      <UpdateButton
                        size="sm"
                        onClick={() => {
                          setUpdateData(val)
                          setIsUpdateForm(true)
                        }}
                      />
                    </ButtonGroup>
                  </TdFixed>
                </tr>
              )
            })}
          </tbody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                  if (index == data.length - 1) {
                    return TableNumber(page, dataLength, index)
                  }
                })
            }
            dataNumber={data.map((res, index) => {
              if (index == 0) {
                return TableNumber(page, dataLength, index)
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value)
              setPage(1)
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearchChange} />
            <ExportButton
              loading={loadingExport}
            // disabled={!dataFilter?.filter?.active}
            >
              <Dropdown.Item onClick={() => exportData("excel")}>
                Excel (.xlsx)
              </Dropdown.Item>
            </ExportButton>
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton
            onClick={() => {
              setIsLoading(true);
              ItemOverheadApi.export()
                .then((val) => {
                  const urlFile =
                    REACT_APP_EXPORT_FILE_BASE_URL + val.data.file;
                  FileSaver.saveAs(urlFile, val.data.file);
                })
                .catch((res) => alert(res))
                .finally(() => {
                  setIsLoading(false);
                });
            }}
            className="mr-2"
          />
          <CreateButton onClick={() => setIsCreateForm(true)} /> */}
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  )
}

export default RegistrasiOverhead
