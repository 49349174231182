// React
import React, { useState, useEffect } from "react";

// Component
import { Row, Col, Modal, Dropdown } from "react-bootstrap";
import _ from "lodash";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  SelectMonth,
  Select as SelectData,
  Input,
  CreateButton,
} from "components";
import { DateConvert, TableNumber, RupiahConvert } from "utilities";

// API
import { HargaSatuanRataRataPenyusutanApi } from "api";

// Form
import { Formik } from "formik";
import * as Yup from "yup";
import { ExportButton } from "./ExportButton";
import FileSaver from "file-saver";
// import { useIsGuest } from "hooks";

const HargaSatuanRataRataPenyusutan = ({ setNavbarTitle }) => {
  const today = new Date();
  // const isGuest = useIsGuest();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dateSelected, setDateSelected] = useState({
    month: DateConvert(today).defaultMonth,
    year: DateConvert(today).defaultYear,
  });

  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  // Modal
  const [dataModal, setDataModal] = useState(false);
  const [modalConfigPosting, setModalConfigPosting] = useState({
    show: false,
    data: {
      tgl_posting: "",
      akumulasi_harga: "",
      akumulasi_qty: "",
    },
  });
  const [loadingExport, setLoadingExport] = useState(false);
  const date = `${dateSelected.year}-${dateSelected.month}-01`;

  const getInitialData = () => {
    const query = {
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      tanggal: date,
    };

    setIsPageLoading(true);

    HargaSatuanRataRataPenyusutanApi.page(query)
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setPaginationConfig({
          page: "1",
          dataLength: "10",
          totalPage: "1",
          dataCount: "0",
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    HargaSatuanRataRataPenyusutanApi.export({
      tanggal: date,
      tipe,
    })
      .then((res) => {
        const data = res.data.data;
        // const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data;
        FileSaver.saveAs(data, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle("Harga Satuan Rata-rata Penyusutan");
    getInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    dateSelected.month,
    dateSelected.year,
  ]);

  const Select = (props) => (
    <select
      {...props}
      className={`custom-select custom-select ${props.className}`}
    >
      {props.children}
    </select>
  );

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <Th>No</Th>
            <Th>Kode Aset</Th>
            <Th>Item Aset</Th>
            <Th>Kategori Aset</Th>
            <Th>Jenis Aset</Th>
            <Th>Harga Satuan Rata-rata Penyusutan</Th>
          </tr>
        </thead>
        <tbody>
          {data.map((val, index) => (
            <tr key={index}>
              <Td>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </Td>
              <Td>{val.kode_item_aset ? val.kode_item_aset : "-"}</Td>
              <Td>{val.nama_item_aset ? val.nama_item_aset : "-"}</Td>
              <Td>{val.nama_kategori_aset ? val.nama_kategori_aset : "-"}</Td>
              <Td>{val.nama_jenis_aset ? val.nama_jenis_aset : "-"}</Td>
              <Td className="text-right">
                {val.harga_satuan
                  ? RupiahConvert(parseFloat(val.harga_satuan ?? 0).toString())
                      .getWithComa
                  : "-"}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  const DataModal = () => {
    const getDataHSR = (bulan, tahun, setValues, values) => {
      if (bulan !== "" && tahun !== "") {
        const tanggal = `${tahun}-${bulan}-01`;

        HargaSatuanRataRataPenyusutanApi.hsr({ tanggal }).then((res) =>
          setValues({
            bulan: bulan,
            tahun: tahun,
            status_posting: res.data.data.status_posting,
            akumulasi_harga: res.data.data.total_penyusutan,
            akumulasi_qty: res.data.data.total_realisasi,
            akumulasi_total:
              res.data.data.total_realisasi > 0
                ? res.data.data.total_penyusutan / res.data.data.total_realisasi
                : "0",
            posting:
              res.data.data.status_posting === "Belum Di Posting"
                ? false
                : true,
          })
        );
      } else if (bulan !== "") {
        setValues({
          ...values,
          bulan,
        });
      } else if (tahun !== "") {
        setValues({
          ...values,
          tahun,
        });
      }
    };

    const formInitialValues = {
      bulan: "",
      tahun: "",
      status_posting: "",
      akumulasi_harga: "",
      akumulasi_qty: "",
      akumulasi_total: "",
      posting: true,
    };

    const formValidationSchema = Yup.object().shape({
      bulan: Yup.string().required("Bulan Posting wajib diisi"),
      tahun: Yup.string().required("Tahun Posting wajib diisi"),
      // akumulasi_harga: Yup.number()
      //   .min(1, "Akumulasi Qty. Harga Penyusutan wajib terisi minimal 1")
      //   .required("Akumulasi Harga Penyusutan Aset tidak boleh kosong"),
      // akumulasi_qty: Yup.number()
      //   .min(1, "Akumulasi Qty. Realisasi Aset wajib terisi minimal 1")
      //   .required("Akumulasi Qty. Realisasi Aset tidak boleh kosong"),
    });

    const formSubmitHandler = (values, { setSubmitting }) => {
      const tgl_posting = `${values.tahun}-${values.bulan}-01`;

      setDataModal(false);
      setModalConfigPosting({
        show: true,
        data: {
          tgl_posting,
          akumulasi_harga: values.akumulasi_harga,
          akumulasi_qty: values.akumulasi_qty,
        },
      });
      setSubmitting(false);
    };

    return (
      <Modal show={dataModal} onHide={() => setDataModal(false)} size="md">
        <Modal.Header closeButton>
          <b>Posting Harga Satuan Rata-rata Penyusutan</b>
        </Modal.Header>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            setValues,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Row>
                    <Col lg="6">
                      <SelectData
                        label="Bulan Posting"
                        name="bulan"
                        defaultValue={values.bulan}
                        onChange={(e) =>
                          getDataHSR(
                            e.target.value,
                            values.tahun,
                            setValues,
                            values
                          )
                        }
                        error={errors.bulan && touched.bulan && true}
                        errorText={
                          errors.bulan && touched.bulan && errors.bulan
                        }
                      >
                        <option value="">Pilih Bulan</option>
                        <option value="01">Januari</option>
                        <option value="02">Februari</option>
                        <option value="03">Maret</option>
                        <option value="04">April</option>
                        <option value="05">Mei</option>
                        <option value="06">Juni</option>
                        <option value="07">Juli</option>
                        <option value="08">Agustus</option>
                        <option value="09">September</option>
                        <option value="10">Oktober</option>
                        <option value="11">November</option>
                        <option value="12">Desember</option>
                      </SelectData>
                    </Col>

                    <Col lg="6">
                      <SelectData
                        label="Tahun Posting"
                        name="tahun"
                        defaultValue={values.tahun}
                        onChange={(e) =>
                          getDataHSR(
                            values.bulan,
                            e.target.value,
                            setValues,
                            values
                          )
                        }
                        error={errors.tahun && touched.tahun && true}
                        errorText={
                          errors.tahun && touched.tahun && errors.tahun
                        }
                        disabled={values.bulan === "" ? true : false}
                      >
                        <option value="">Pilih Tahun</option>
                        {_.range(today.getFullYear(), 1999).map((val) => (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        ))}
                      </SelectData>
                    </Col>
                  </Row>

                  <Input
                    label="Status Posting"
                    type="text"
                    value={values.status_posting}
                    error={
                      Boolean(errors.status_posting) && touched.status_posting
                    }
                    errorText={errors.status_posting}
                    readOnly
                  />

                  {/* <Input
                    label="Akumulasi Harga Penyusutan Aset"
                    type="text"
                    className="text-right"
                    value={
                      values.akumulasi_harga !== ""
                        ? RupiahConvert(
                            parseFloat(values.akumulasi_harga).toString()
                          ).getWithComa
                        : ""
                    }
                    error={
                      Boolean(errors.akumulasi_harga) && touched.akumulasi_harga
                    }
                    errorText={errors.akumulasi_harga}
                    readOnly
                  />

                  <Input
                    label="Akumulasi Qty. Realisasi Aset"
                    type="text"
                    className="text-right"
                    value={
                      values.akumulasi_qty !== ""
                        ? `${values.akumulasi_qty} Depreciation Value`
                        : ""
                    }
                    error={
                      Boolean(errors.akumulasi_qty) && touched.akumulasi_qty
                    }
                    errorText={errors.akumulasi_qty}
                    readOnly
                  />

                  <Input
                    label="Harga Satuan Rata-Rata Penyusutan Per Depreciation Value"
                    type="text"
                    className="text-right"
                    value={
                      values.akumulasi_total !== ""
                        ? `${
                            RupiahConvert(
                              parseFloat(values.akumulasi_total).toString()
                            ).getWithComa
                          }/Depreciation Value`
                        : ""
                    }
                    error={
                      Boolean(errors.akumulasi_total) && touched.akumulasi_total
                    }
                    errorText={errors.akumulasi_total}
                    readOnly
                  /> */}
                </Modal.Body>

                <Modal.Footer>
                  <div className="text-right">
                    <ActionButton
                      type="submit"
                      text="Posting"
                      className="px-4"
                      disable={values.posting}
                      loading={isSubmitting}
                    />
                  </div>
                </Modal.Footer>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  };

  const ModalBody = () => {
    const [isPosting, setIsPosting] = useState(false);
    const postingDataHandler = () => {
      setIsPosting(true);
      // SEND DATA TO SERVER
      HargaSatuanRataRataPenyusutanApi.create(modalConfigPosting.data)
        .then(() => {
          // setDataHistoryUpah Penunjang Finishing(newData)
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Posting data berhasil disimpan!",
          });
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Posting data gagal disimpan!",
          });
        })
        .finally(() => {
          setIsPosting(false);
          setModalConfigPosting({
            ...modalConfigPosting,
            show: false,
          });
          getInitialData();
        });
    };

    return (
      <Modal
        show={modalConfigPosting.show}
        size="sm"
        onHide={() => {
          setModalConfigPosting({
            ...modalConfigPosting,
            show: false,
          });
        }}
      >
        <Modal.Header closeButton>
          <b>Posting Data</b>
        </Modal.Header>

        <Modal.Body className="text-center">
          <p>
            Apa Anda yakin akan memposting data ini?
            <br />
            <small className="text-danger">
              Jurnal yang telah diposting tidak bisa di edit!
            </small>
          </p>
          <div>
            <ActionButton
              variant="primary"
              text="Posting"
              className="m-1"
              onClick={postingDataHandler}
              loading={isPosting}
            />

            <ActionButton
              variant="danger"
              text="Batal"
              className="m-1"
              onClick={() => {
                setModalConfigPosting({
                  ...modalConfigPosting,
                  show: false,
                });
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              // value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <ExportButton
              loading={loadingExport}
              // disabled={!dataFilter?.filter?.active}
            >
              <Dropdown.Item onClick={() => exportData("excel")}>
                Excel (.xlsx)
              </Dropdown.Item>
            </ExportButton>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex align-items-center">
            <SelectMonth
              noMargin
              size="md"
              wrapperClassName="m-1"
              style={{ width: 150 }}
              defaultValue={dateSelected.month}
              onChange={(e) => {
                setDateSelected({
                  ...dateSelected,
                  month: e.target.value,
                });

                setPaginationConfig({
                  ...paginationConfig,
                  page: "1",
                });
              }}
            />
            <Select
              className="m-1"
              style={{ width: 100 }}
              defaultValue={dateSelected.year}
              onChange={(e) => {
                setDateSelected({
                  ...dateSelected,
                  year: e.target.value,
                });

                setPaginationConfig({
                  ...paginationConfig,
                  page: "1",
                });
              }}
            >
              <option value="">Pilih Tahun</option>
              {_.range(today.getFullYear(), 1999).map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </Select>

            <CreateButton
              text="Posting Data"
              variant="primary"
              onClick={() => setDataModal(true)}
            />
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <PageContent />
          <DataModal />
          <ModalBody />
        </>
      )}
    </CRUDLayout>
  );
};

export default HargaSatuanRataRataPenyusutan;
