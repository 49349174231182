import {
  ButtonDetail,
  ButtonFilter,
  InputSearch,
  tableNumber
} from "@bhawanadevteam/react-core"
import {
  Alert,
  CRUDLayout,
  DataStatus,
  Pagination,
  ReadButton,
  TBody,
  THead,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from "components"
import FileSaver from "file-saver"
import { Formik } from "formik"
import { debounce } from "lodash"
import { useState } from "react"
import { ButtonGroup, Dropdown } from "react-bootstrap"
import { useQuery } from "react-query"
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities"
import WIPProduksiAsetApi from "../__WIProduksiAsetApi__"
import { ExportButton } from "./ExportButton"
import { ModalDetail } from "./ModalDetail"
import { ModalFilter } from "./ModalFilter"

export const TableList = ({ type }) => {
  const checkTab = type === "alatMesin" ? "alat_mesin" : type;
  const checkTabTitle = type === "alatMesin" ? "Alat Mesin" : type;
  const [modalConfig, setModalConfig] = useState({ show: false, data: {} });
  const [loadingExport, setLoadingExport] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });
  const [filter, setFilter] = useState({
    q: "",
    [`tgl_wip_${checkTab}_mulai`]: undefined,
    [`tgl_wip_${checkTab}_selesai`]: undefined,
    tgl_realisasi_produksi_aset_mulai: undefined,
    tgl_realisasi_produksi_aset_selesai: undefined,
    [`id_item_${checkTab}`]: undefined,
    status_approval: undefined,
    active: false,
  });
  const [modalFilter, setModalFilter] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    per_page: 10,
  });

  const getList = useQuery([type, "list", filter], () =>
    WIPProduksiAsetApi.getList({
      ...filter,
      ...paginationConfig,
      id_buaso:
        type === "bahan"
          ? 1
          : type === "upah"
          ? 2
          : type === "alatMesin"
          ? 3
          : type === "subkon"
          ? 4
          : 5,
    }).catch(() =>
      setAlertConfig({
        ...alertConfig,
        bahan: {
          show: true,
          variant: "danger",
          text: "Data WIP Produksi Bahan Gagal Dimuat !!!",
        },
      })
    )
  );

  const onPaginationChange = ({ selected }) =>
    setPaginationConfig({
      ...paginationConfig,
      [type]: { ...paginationConfig[type], page: selected + 1 },
    });

  const onPaginationDataLengthChange = (e) =>
    setPaginationConfig({
      ...paginationConfig,
      [type]: {
        ...paginationConfig[type],
        page: 1,
        per_page: e.target.value,
      },
    });

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  // FORM VALUES FILTER
  const formInitialValuesFilter = {
    [`tgl_wip_${checkTab}_mulai`]: filter?.[`tgl_wip_${checkTab}_mulai`],
    [`tgl_wip_${checkTab}_selesai`]: filter?.[`tgl_wip_${checkTab}_selesai`],
    tgl_realisasi_produksi_aset_mulai:
      filter?.tgl_realisasi_produksi_aset_mulai,
    tgl_realisasi_produksi_aset_selesai:
      filter?.tgl_realisasi_produksi_aset_selesai,
    [`id_item_${checkTab}`]: filter?.[`id_item_${checkTab}`],
    status_approval: filter?.status_approval,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    WIPProduksiAsetApi.export({ ...filter, tipe }, checkTab)
      .then((res) => {
        const data = res.data.data;
        // const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data;
        FileSaver.saveAs(data, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  return (
    <>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap mr-2"
              onClick={onFilterButtonClickkHandler}
              active={filter.active}
            />
            <ExportButton loading={loadingExport}>
              <Dropdown.Item onClick={() => exportData("excel")}>
                Excel (.xlsx)
              </Dropdown.Item>
            </ExportButton>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <div>
        <small className="font-weight-bold">List Data WIP Produksi Aset</small>
      </div>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        showCloseButton
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            [type]: {
              ...alertConfig,
              show: false,
            },
          })
        }
      />
      {getList.isFetching ? (
        <DataStatus text="Memuat Data..." loading />
      ) : (
        <>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <ThFixed>Informasi WIP Produksi Aset</ThFixed>
                <ThFixed>Informasi Realisasi Produksi Aset</ThFixed>
                <Th className="text-capitalize">Item {checkTabTitle}</Th>
                <Th>Qty.</Th>
                <Th>Harga Satuan</Th>
                <Th>Jumlah</Th>
                <Th>Akun COA</Th>
                <Th>Keterangan Jurnal</Th>
              </Tr>
            </THead>
            <TBody>
              {getList?.data?.data?.length > 0 ? (
                getList?.data?.data?.map((val, index) => {
                  const jumlah =
                    parseFloat(val?.qty_realisasi ?? 0) *
                    parseFloat(val?.[`harga_satuan_${checkTab}`] ?? 0);
                  return (
                    <Tr key={index}>
                      <TdFixed>
                        {tableNumber({
                          pageCurrent: paginationConfig?.page,
                          dataLength: paginationConfig?.per_page,
                          index,
                        })}
                      </TdFixed>
                      <TdFixed>
                        <ButtonGroup size="sm">
                          <ReadButton
                            icon
                            noText
                            variant="primary"
                            onClick={() =>
                              setModalConfig({
                                data: {
                                  ...val,
                                  tgl_wip: val?.[`tgl_wip_${checkTab}`],
                                  no_wip: val?.[`no_wip_${checkTab}`],
                                },
                                show: true,
                              })
                            }
                          />
                        </ButtonGroup>
                      </TdFixed>
                      <Td>
                        <div>
                          {
                            DateConvert(new Date(val?.[`tgl_wip_${checkTab}`]))
                              .defaultDMY
                          }
                        </div>
                        <div>{val?.[`no_wip_${checkTab}`] ?? "-"}</div>
                      </Td>
                      <Td>
                        <div>
                          {
                            DateConvert(
                              new Date(val?.tgl_realisasi_produksi_aset)
                            ).defaultDMY
                          }
                        </div>
                        <div>{val.no_realisasi_produksi_aset ?? "-"}</div>
                      </Td>
                      <Td>{val?.nama_item ?? "-"}</Td>
                      <Td className="text-right">
                        {
                          DecimalConvert(parseFloat(val?.qty_realisasi ?? 0))
                            .getWithComa
                        }
                      </Td>
                      <Td className="text-right">
                        {
                          RupiahConvert(
                            parseFloat(
                              val?.[`harga_satuan_${checkTab}`] ?? 0
                            ).toString()
                          ).getWithComa
                        }
                      </Td>
                      <Td className="text-right">
                        {
                          RupiahConvert(parseFloat(jumlah).toString())
                            .getWithComa
                        }
                      </Td>
                      <Td>
                        <div>
                          D : {val?.nomor_akun_debet} - {val?.nama_akun_debet}
                        </div>
                        <div>
                          K : {val?.nomor_akun_kredit} - {val?.nama_akun_kredit}
                        </div>
                      </Td>
                      <Td>{val?.keterangan_jurnal ?? "-"}</Td>
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={paginationConfig?.per_page}
            dataNumber={
              paginationConfig?.page * paginationConfig?.per_page -
              paginationConfig?.per_page +
              1
            }
            dataPage={
              paginationConfig?.dataCount < paginationConfig?.per_page
                ? paginationConfig?.dataCount
                : paginationConfig?.page * paginationConfig?.per_page
            }
            dataCount={getList?.data?.data_count}
            currentPage={paginationConfig?.page}
            totalPage={getList?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter
            toggle={onFilterButtonClickkHandler}
            checkTab={checkTab}
            checkTabTitle={checkTabTitle}
            type={type}
          />
        </Formik>
      )}

      {modalConfig.show && (
        <ModalDetail
          setModalConfig={setModalConfig}
          modalConfig={modalConfig}
          tab={checkTabTitle}
        />
      )}
    </>
  );
};
