// React
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

// Component
import {
  ActionButton,
  Alert,
  CreateButton,
  DataStatus,
  Input,
  InputSearch,
  Modal,
  Pagination,
  SelectMonth,
  Table,
  Td,
  Th,
} from "components"
import { Formik } from "formik"
import _ from "lodash"
import { Col, Dropdown, ModalBody, ModalFooter, Row } from "react-bootstrap"
import { IoAlertCircleOutline } from "react-icons/io5"
import {
  DateConvert,
  RupiahConvert,
  TableNumber
} from "utilities"
// API
import { HargaSatuanRataRataBarangJadiApi } from "api"
import FileSaver from "file-saver"
import { ExportButton } from "./ExportButton"

const PrecastSection = ({ setNavbarTitle }) => {
  const location = useLocation();
  const today = new Date();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
    bulan: DateConvert(today).defaultMonth,
    tahun: DateConvert(today).defaultYear,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);
  const [modalConfig, setModalConfig] = useState({ show: false });
  const [modalConfirmConfig, setModalConfirmConfig] = useState({
    show: false,
    data: {},
  });
  const [loadingExport, setLoadingExport] = useState(false);

  const tableStyle = {
    tdClassname: "p-1 px-2 align-middle",
    tdStyle: { width: "auto" },
    tdFixedStyle: { width: "100px" },
  };

  var month = 0; // January

  const getInitialData = () => {
    setIsPageLoading(true);

    let value = {};
    if (searchConfig.bulan === "13") {
      value = {
        q: searchConfig.key,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
        tahun: searchConfig.tahun,
        flag: "PRC",
      };
    } else {
      value = {
        q: searchConfig.key,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
        tanggal: `${searchConfig.tahun}-${searchConfig.bulan}-01`,
        flag: "PRC",
      };
    }

    HargaSatuanRataRataBarangJadiApi.page(value)
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: text.target.value,
        });
        setPaginationConfig({
          page: "1",
          dataLength: "10",
          totalPage: "1",
          dataCount: "0",
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    HargaSatuanRataRataBarangJadiApi.export({
      tanggal: `${searchConfig.tahun}-${searchConfig.bulan}-01`,
      flag: "PRC",
      tipe,
    })
      .then((res) => {
        const data = res.data.data;
        // const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data;
        FileSaver.saveAs(data, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNavbarTitle,
    searchConfig.key,
    searchConfig.bulan,
    searchConfig.tahun,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  const Select = (props) => (
    <>
      {props.label && <small>{props.label}</small>}
      <select
        {...props}
        className={`custom-select custom-select ${props.className}`}
      >
        {props.children}
      </select>
    </>
  );

  // KOMPONEN MODAL FORM POSTING
  const ModalPosting = () => {
    const formInitialValues = {
      bulan_posting: searchConfig.bulan,
      tahun_posting: searchConfig.tahun,
      status_posting: "",
      akumulasi_biaya_overhead: "",
      akumulasi_qty_realisasi: "",
      harga_satuan_rata_rata: "",
    };

    const formSubmitHandler = (values) => {
      // FORMAT DATA SESUAI POSTMAN
      const finalValues = {
        tgl_posting: `${values.tahun_posting}-${values.bulan_posting}-01`,
        akumulasi_harga: values.akumulasi_biaya_overhead,
        akumulasi_qty: values.akumulasi_qty_realisasi,
      };
      setModalConfig({ show: false });
      setModalConfirmConfig({
        show: true,
        data: finalValues,
      });
    };

    const ModalForm = ({ formik }) => {
      const [isFetchingModal, setIsFetchingModal] = useState(true);
      const {
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setValues,
        handleChange,
        handleSubmit,
      } = formik;

      const fetchHSRData = () => {
        setIsFetchingModal(true);
        const tanggal = `${values.tahun_posting}-${values.bulan_posting}-01`;
        HargaSatuanRataRataBarangJadiApi.getHSRData({
          tanggal: tanggal,
          flag: "PRC",
        })
          .then((res) => {
            const { data } = res.data;
            setValues({
              ...values,
              status_posting: data.status_posting,
            });
            setIsFetchingModal(false);
          })
          .catch((err) => {
            setModalConfig({
              show: false,
            });
            setAlertConfig({
              show: true,
              text: "Tidak dapat memuat data ",
              variant: "danger",
            });
          });
      };

      useEffect(() => {
        fetchHSRData();
        return () => {};
      }, [values.bulan_posting, values.tahun_posting]);

      return (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            {isFetchingModal ? (
              <DataStatus text="Memuat Data..." loading={isFetchingModal} />
            ) : (
              <>
                <Row>
                  <Col md>
                    <SelectMonth
                      label="Bulan Posting"
                      name="bulan_posting"
                      size="md"
                      wrapperClassName="m-1"
                      defaultValue={values.bulan_posting}
                      onChange={(val) =>
                        setFieldValue("bulan_posting", val.target.value)
                      }
                    />
                  </Col>

                  <Col md>
                    <Select
                      label="Tahun Posting"
                      name="tahun_posting"
                      className="m-1"
                      defaultValue={values.tahun_posting} //untuk menyesuaikan nilai yang telah dipilih
                      onChange={(val) =>
                        setFieldValue("tahun_posting", val.target.value)
                      }
                    >
                      {_.range(today.getFullYear(), 1999).map((e) => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Input
                  disabled
                  label="Status Posting"
                  name="status_posting"
                  value={values.status_posting}
                />
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {!isFetchingModal && (
              <ActionButton
                disable={
                  values.status_posting === "Belum Di Posting" ? false : true
                }
                type="submit"
                text="Posting"
                className="px-4"
                loading={isSubmitting}
              />
            )}
          </ModalFooter>
        </form>
      );
    };

    return (
      <Modal
        closeButton
        show={modalConfig.show}
        onHide={() => {
          setModalConfig({
            ...modalConfig,
            show: false,
          });
        }}
        title="Posting Harga Satuan Rata-Rata Precast"
      >
        <Formik initialValues={formInitialValues} onSubmit={formSubmitHandler}>
          {(formik) => <ModalForm formik={formik} />}
        </Formik>
      </Modal>
    );
  };

  // KOMPONEN DIALOG KONFIRMASI
  const ModalConfirm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmitHandler = () => {
      setIsSubmitting(true);
      // KIRIM DATA KE SERVER
      const finalValues = { ...modalConfirmConfig.data, flag: "PRC" };
      HargaSatuanRataRataBarangJadiApi.save(finalValues)
        .then(() => {
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil diposting!",
          });
          setModalConfirmConfig({
            show: false,
            data: {},
          });
          getInitialData();
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal diposting!",
          });
          setModalConfirmConfig({
            show: false,
            data: {},
          });
        })
        .finally(() => setIsSubmitting(false));
    };

    return (
      <Modal
        closeButton
        show={modalConfirmConfig.show}
        onHide={() => {
          setModalConfirmConfig({
            show: false,
            data: {},
          });
        }}
        title="Konfirmasi Posting"
      >
        <ModalBody className="text-center">
          <IoAlertCircleOutline size={80} className="text-danger mb-3" />
          <p>
            <b>Apakah anda yakin akan memposting data ini?</b>
            <br />
            <small className="text-danger">
              Jurnal yang telah diposting tidak dapat diedit!
            </small>
          </p>
          <div className="d-flex justify-content-center">
            <ActionButton
              variant="outline-danger"
              text="Batal"
              className="m-1 px-4"
              onClick={() => {
                setModalConfirmConfig({
                  show: false,
                  data: {},
                });
              }}
            />
            <ActionButton
              variant="primary"
              text="Posting"
              className="m-1 px-4"
              loading={isSubmitting}
              onClick={onSubmitHandler}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <Th>No</Th>
            <Th>Kode Precast</Th>
            <Th>Item Precast</Th>
            <Th>Satuan Pakai</Th>
            <Th>Harga Satuan Rata-Rata</Th>
          </tr>
        </thead>
        <tbody>
          {data.map((val, index) => (
            <tr key={index}>
              <Td className={tableStyle.tdClassname} style={tableStyle.tdStyle}>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </Td>
              <Td
                className={tableStyle.tdClassname}
                style={tableStyle.tdFixedStyle}
              >
                {val?.kode_item ?? "-"}
              </Td>
              <Td className={tableStyle.tdClassname}>
                {val?.nama_item ?? "-"}
              </Td>
              <Td>{val?.nama_satuan ?? "-"}</Td>
              <Td
                className={`${tableStyle.tdClassname} text-right`}
                style={tableStyle.tdStyle}
              >
                {
                  RupiahConvert(String(parseFloat(val?.harga_satuan || 0)))
                    .getWithComa
                }
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <>
      {/* head section */}
      <Row className="mb-2 d-flex justify-content-around align-items-end">
        <Col md={4}>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearchChange} />
            <ExportButton
              loading={loadingExport}
              // disabled={!dataFilter?.filter?.active}
            >
              <Dropdown.Item onClick={() => exportData("excel")}>
                Excel (.xlsx)
              </Dropdown.Item>
            </ExportButton>
          </div>
        </Col>

        <Col className="d-flex justify-content-end">
          <SelectMonth
            noMargin
            size="md"
            wrapperClassName="m-1"
            style={{ width: 150 }}
            defaultValue={searchConfig.bulan}
            onChange={(val) => {
              setPaginationConfig({
                ...paginationConfig,
                page: "1",
              });
              setSearchConfig({ ...searchConfig, bulan: val.target.value });
            }}
          />

          <Select
            name="tahun"
            className="m-1"
            style={{ width: 100 }}
            defaultValue={searchConfig.tahun} //untuk menyesuaikan nilai yang telah dipilih
            onChange={(val) => {
              setPaginationConfig({
                ...paginationConfig,
                page: "1",
              });
              setSearchConfig({ ...searchConfig, tahun: val.target.value });
            }}
          >
            {_.range(today.getFullYear(), 1999).map((e) => (
              <option value={e}>{e}</option>
            ))}
          </Select>

          <CreateButton
            className="m-1"
            text="Posting Data"
            onClick={() => setModalConfig({ show: true })}
          />
        </Col>
      </Row>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <b>List Data Harga Satuan Rata-Rata Precast</b>
          <PageContent />
          {modalConfig.show && <ModalPosting />}
          {modalConfirmConfig.show && <ModalConfirm />}
        </>
      )}
    </>
  );
};

export default PrecastSection;
