// React
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

// API
import { MappingCOAApi } from "api"

// Components
import {
  ActionButton,
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  InputSearch,
  Pagination,
  Td,
  Th,
} from "components"
import { TableNumber } from "utilities"
import { GuestMiddleware } from "utilities/GuestMiddleware"

const ListMappingCOA = ({ setNavbarTitle }) => {
  // VARIABLES
  const title = "Mapping Kelompok Item terhadap COA"
  const history = useHistory()
  const location = useLocation()
  const paginationFromBrowserState = location?.state?.pagination

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false)

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false)
  const [searchKey, setSearchKey] = useState("")
  const [searchTime, setSearchTime] = useState(null)
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })

  // STATE DATA KELOMPOK
  const [data, setData] = useState([])
  const [page, setPage] = useState(paginationFromBrowserState?.page ?? 1)
  const [totalPage, setTotalPage] = useState(
    paginationFromBrowserState?.totalPage ?? 1
  )
  const [dataLength, setDataLength] = useState(
    paginationFromBrowserState?.dataLength ?? 10
  )
  const [dataCount, setDataCount] = useState(
    paginationFromBrowserState?.dataCount ?? 0
  )
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false)
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)
    setShowAlert(false)

    MappingCOAApi.page({
      page,
      per_page: dataLength,
      q: searchConfig.key,
    })
      .then((res) => {
        setData(res.data.data)
        setTotalPage(res.data.total_page)
        setDataCount(res.data.data_count)
      })
      .catch((err) => alert(err))
      .finally(() => {
        // if (searchKey !== "") {
        //   setAlertConfig({
        //     variant: "primary",
        //     text: `Hasil Pencarian : ${searchKey}`,
        //   });
        //   setShowAlert(true);
        // }
        setIsLoading(false)
      })
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        })
        setShowAlert(key ? true : false)
        setAlertConfig({
          variant: "primary",
          text: "Hasil dari pencarian:" + key,
        })
      }, 750)
    )
  }

  const checkPaginationHandler = () =>
    new Promise((resolve, reject) => {
      if (Boolean(paginationFromBrowserState)) {
        setPage(paginationFromBrowserState.page)
        setDataLength(paginationFromBrowserState.dataLength)
        setTotalPage(paginationFromBrowserState.totalPage)
        setDataCount(paginationFromBrowserState.dataCount)

        resolve({
          page: paginationFromBrowserState.page,
          dataLength: paginationFromBrowserState.dataLength,
          totalPage: paginationFromBrowserState.totalPage,
          dataCount: paginationFromBrowserState.dataCount,
        })
      }

      reject({})
    })

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })

        setShowAlert(true)
      }
    }
  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title) // SET JUDUL NAVBAR
    checkPaginationHandler().finally(() => getData())
    checkAlert()
  }, [setNavbarTitle])

  useEffect(() => {
    getData()
  }, [page, dataLength, searchConfig.key])

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <thead>
            <tr className="text-center">
              <Th>No.</Th>
              <Th>BUASO</Th>
              <Th>Kelompok Item</Th>
              <Th>Kode Akun</Th>
              <Th>Nama Akun</Th>
              <Th>Posisi</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => {
              return (
                <tr key={val.id_kelompok}>
                  <Td className="text-center" width="20px">
                    {TableNumber(page, dataLength, index)}
                  </Td>
                  <Td width="100px">{val.nama_buaso ?? "-"}</Td>
                  <Td width="180px">{val.nama_kelompok ?? "-"}</Td>
                  <Td width="100px">{val.nomor_akun ?? "-"}</Td>
                  <Td>{val.nama_akun ?? "-"}</Td>
                  <Td width="100px">
                    {val.posisi === "D"
                      ? "Debit"
                      : val.posisi === "K"
                        ? "Kredit"
                        : "-"}
                  </Td>
                </tr>
              )
            })}
          </tbody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1)
            setDataLength(e.target.value)
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      </>
    )
  }

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <div className="d-flex mb-3">
              <InputSearch onChange={onInputSearchChange} />
            </div>
          </CRUDLayout.HeadSearchSection>

          {/* Button Section */}
          <CRUDLayout.HeadButtonSection>
            <GuestMiddleware>
              <ActionButton
                text="Ubah Data"
                onClick={() =>
                  history.push("/transaksi/mapping-kelompok-item-coa/ubah", {
                    pagination: {
                      page: page,
                      totalPage: totalPage,
                      dataCount: dataCount,
                      dataLength: dataLength,
                    },
                  })
                }
              />
            </GuestMiddleware>
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        {/* Alert */}
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />

        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : !Array.isArray(data) ? (
          <DataStatus text="Tidak dapat memuat data" />
        ) : data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </CRUDLayout>
    </>
  )
}

export default ListMappingCOA
